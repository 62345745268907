
import { defineComponent, PropType } from "vue";
import "jquery/dist/jquery.min.js";
import variables from "@/router/api";

export default defineComponent({
  name: "policy-receipts-table",
  components: {},
  props: {
    receipts: Array as PropType<Record<string, any>[]>,
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
  },
});
